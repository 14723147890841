/*jslint browser: true*/
/*global $, jQuery, alert*/

$(document).ready(function () {
  (function ($) {
    $('#page-loading').fadeOut('slow');
    $('#page-loaded').fadeIn('slow');

    // Password policy
    var pwStrengthOptions = {};
    pwStrengthOptions.ui = {
      showErrors: true
    };
    $('.form-register input[name=password]').pwstrength(pwStrengthOptions);

    // Generate password
    $('#generatePassword').click(function(e) {
      e.preventDefault();
      var $newPassword = generatePassword(8, false);

      $(this).closest('form').find(".form-group-password .progress").hide();
      $(this).closest('form').find("input:password").val($newPassword);
      $(this).val(Lang.get('jspublic.user_password_generate_label_will_be_mailed'));
      $(this).toggleClass("btn-warning btn-success");
    });

    // Copy to clipboard
    var clipboard = new ClipboardJS('.btn');
    clipboard.on('success', function(e) {
      alert(Lang.get('jspublic.recipe_share_link_copied'));

      e.clearSelection();
    });

    // Spinner buttons
    $('.btn-spinner').click(function () {
        $(this).find('.fa').hide(); // hide existing button icon
        $(this).find('.fa-spinner').show();
    });

    // Hide spinner again when frontend validation of form failed
    $('form:not(.form-modal)').validator().on('submit', function (e) {
      if (e.isDefaultPrevented()) {
        $(this).find('.fa').show(); // show the other buttons again
        $(this).find('.fa-spinner').hide();
      }
    });
  }(jQuery));
});